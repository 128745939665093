import { copyToAudioBuffer } from './ruffle-imports.js';
import * as wasm from './ruffle_web_bg.wasm';

const lAudioContext = (typeof AudioContext !== 'undefined' ? AudioContext : webkitAudioContext);

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 36) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachegetUint8Memory0 = null;
function getUint8Memory0() {
    if (cachegetUint8Memory0 === null || cachegetUint8Memory0.buffer !== wasm.memory.buffer) {
        cachegetUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachegetUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let cachegetFloat64Memory0 = null;
function getFloat64Memory0() {
    if (cachegetFloat64Memory0 === null || cachegetFloat64Memory0.buffer !== wasm.memory.buffer) {
        cachegetFloat64Memory0 = new Float64Array(wasm.memory.buffer);
    }
    return cachegetFloat64Memory0;
}

let cachegetInt32Memory0 = null;
function getInt32Memory0() {
    if (cachegetInt32Memory0 === null || cachegetInt32Memory0.buffer !== wasm.memory.buffer) {
        cachegetInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachegetInt32Memory0;
}

let WASM_VECTOR_LEN = 0;

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length);
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len);

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3);
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}

function makeMutClosure(arg0, arg1, dtor, f) {
    const state = { a: arg0, b: arg1, cnt: 1, dtor };
    const real = (...args) => {
        // First up with a closure we increment the internal reference
        // count. This ensures that the Rust closure environment won't
        // be deallocated while we're invoking it.
        state.cnt++;
        const a = state.a;
        state.a = 0;
        try {
            return f(a, state.b, ...args);
        } finally {
            if (--state.cnt === 0) {
                wasm.__wbindgen_export_2.get(state.dtor)(a, state.b);

            } else {
                state.a = a;
            }
        }
    };
    real.original = state;

    return real;
}
function __wbg_adapter_30(arg0, arg1) {
    wasm._dyn_core__ops__function__FnMut_____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h49ea91de80311604(arg0, arg1);
}

function __wbg_adapter_33(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h19c3bf30b7950ab3(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_36(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h19c3bf30b7950ab3(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_39(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h0d6d82fe6602699d(arg0, arg1, arg2);
}

function __wbg_adapter_42(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h19c3bf30b7950ab3(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_45(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h19c3bf30b7950ab3(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_48(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h19c3bf30b7950ab3(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_51(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h19c3bf30b7950ab3(arg0, arg1, addHeapObject(arg2));
}

function __wbg_adapter_54(arg0, arg1, arg2) {
    wasm._dyn_core__ops__function__FnMut__A____Output___R_as_wasm_bindgen__closure__WasmClosure___describe__invoke__h76bde797965385ea(arg0, arg1, addHeapObject(arg2));
}

let stack_pointer = 32;

function addBorrowedObject(obj) {
    if (stack_pointer == 1) throw new Error('out of js stack');
    heap[--stack_pointer] = obj;
    return stack_pointer;
}

let cachegetUint32Memory0 = null;
function getUint32Memory0() {
    if (cachegetUint32Memory0 === null || cachegetUint32Memory0.buffer !== wasm.memory.buffer) {
        cachegetUint32Memory0 = new Uint32Array(wasm.memory.buffer);
    }
    return cachegetUint32Memory0;
}

function passArrayJsValueToWasm0(array, malloc) {
    const ptr = malloc(array.length * 4);
    const mem = getUint32Memory0();
    for (let i = 0; i < array.length; i++) {
        mem[ptr / 4 + i] = addHeapObject(array[i]);
    }
    WASM_VECTOR_LEN = array.length;
    return ptr;
}

let cachegetFloat32Memory0 = null;
function getFloat32Memory0() {
    if (cachegetFloat32Memory0 === null || cachegetFloat32Memory0.buffer !== wasm.memory.buffer) {
        cachegetFloat32Memory0 = new Float32Array(wasm.memory.buffer);
    }
    return cachegetFloat32Memory0;
}

function getArrayF32FromWasm0(ptr, len) {
    return getFloat32Memory0().subarray(ptr / 4, ptr / 4 + len);
}

function getArrayU8FromWasm0(ptr, len) {
    return getUint8Memory0().subarray(ptr / 1, ptr / 1 + len);
}

function handleError(f) {
    return function () {
        try {
            return f.apply(this, arguments);

        } catch (e) {
            wasm.__wbindgen_exn_store(addHeapObject(e));
        }
    };
}

function passArray8ToWasm0(arg, malloc) {
    const ptr = malloc(arg.length * 1);
    getUint8Memory0().set(arg, ptr / 1);
    WASM_VECTOR_LEN = arg.length;
    return ptr;
}
/**
* An opaque handle to a `RuffleInstance` inside the pool.
*
* This type is exported to JS, and is used to interact with the library.
*/
export class Ruffle {

    static __wrap(ptr) {
        const obj = Object.create(Ruffle.prototype);
        obj.ptr = ptr;

        return obj;
    }

    free() {
        const ptr = this.ptr;
        this.ptr = 0;

        wasm.__wbg_ruffle_free(ptr);
    }
    /**
    * @param {HTMLElement} parent
    * @param {any} js_player
    * @param {boolean} allow_script_access
    * @param {boolean} upgrade_to_https
    */
    constructor(parent, js_player, allow_script_access, upgrade_to_https) {
        var ret = wasm.ruffle_new(addHeapObject(parent), addHeapObject(js_player), allow_script_access, upgrade_to_https);
        return Ruffle.__wrap(ret);
    }
    /**
    * Stream an arbitrary movie file from (presumably) the Internet.
    *
    * This method should only be called once per player.
    * @param {string} movie_url
    * @param {any} parameters
    */
    stream_from(movie_url, parameters) {
        try {
            var ptr0 = passStringToWasm0(movie_url, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            var len0 = WASM_VECTOR_LEN;
            wasm.ruffle_stream_from(this.ptr, ptr0, len0, addBorrowedObject(parameters));
        } finally {
            heap[stack_pointer++] = undefined;
        }
    }
    /**
    * Play an arbitrary movie on this instance.
    *
    * This method should only be called once per player.
    * @param {Uint8Array} swf_data
    * @param {any} parameters
    */
    load_data(swf_data, parameters) {
        try {
            wasm.ruffle_load_data(this.ptr, addHeapObject(swf_data), addBorrowedObject(parameters));
        } finally {
            heap[stack_pointer++] = undefined;
        }
    }
    /**
    */
    play() {
        wasm.ruffle_play(this.ptr);
    }
    /**
    */
    pause() {
        wasm.ruffle_pause(this.ptr);
    }
    /**
    */
    destroy() {
        wasm.ruffle_destroy(this.ptr);
    }
    /**
    * @param {string} name
    * @param {any[]} args
    * @returns {any}
    */
    call_exposed_callback(name, args) {
        var ptr0 = passStringToWasm0(name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        var len0 = WASM_VECTOR_LEN;
        var ptr1 = passArrayJsValueToWasm0(args, wasm.__wbindgen_malloc);
        var len1 = WASM_VECTOR_LEN;
        var ret = wasm.ruffle_call_exposed_callback(this.ptr, ptr0, len0, ptr1, len1);
        return takeObject(ret);
    }
    /**
    * @param {any} observer
    */
    set_trace_observer(observer) {
        wasm.ruffle_set_trace_observer(this.ptr, addHeapObject(observer));
    }
    /**
    * Returns the web AudioContext used by this player.
    * Returns `None` if the audio backend does not use Web Audio.
    * @returns {AudioContext | undefined}
    */
    audio_context() {
        var ret = wasm.ruffle_audio_context(this.ptr);
        return takeObject(ret);
    }
}

export const __wbindgen_object_drop_ref = function(arg0) {
    takeObject(arg0);
};

export const __wbindgen_is_function = function(arg0) {
    var ret = typeof(getObject(arg0)) === 'function';
    return ret;
};

export const __wbindgen_string_new = function(arg0, arg1) {
    var ret = getStringFromWasm0(arg0, arg1);
    return addHeapObject(ret);
};

export const __wbindgen_object_clone_ref = function(arg0) {
    var ret = getObject(arg0);
    return addHeapObject(ret);
};

export const __wbg_onCallbackAvailable_3a48fae397926c96 = function(arg0, arg1, arg2) {
    getObject(arg0).onCallbackAvailable(getStringFromWasm0(arg1, arg2));
};

export const __wbindgen_number_new = function(arg0) {
    var ret = arg0;
    return addHeapObject(ret);
};

export const __wbg_new_df6e6ab7a65c4c4d = function(arg0, arg1) {
    var ret = new Error(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
};

export const __wbg_panic_bbc809dd04a12c60 = function(arg0, arg1) {
    getObject(arg0).panic(getObject(arg1));
};

export const __wbg_displayMessage_c57c20204892da2b = function(arg0, arg1, arg2) {
    getObject(arg0).displayMessage(getStringFromWasm0(arg1, arg2));
};

export const __wbindgen_cb_drop = function(arg0) {
    const obj = takeObject(arg0).original;
    if (obj.cnt-- == 1) {
        obj.a = 0;
        return true;
    }
    var ret = false;
    return ret;
};

export const __wbg_copyToAudioBuffer_58f244be94bac1d2 = function(arg0, arg1, arg2, arg3, arg4) {
    copyToAudioBuffer(getObject(arg0), arg1 === 0 ? undefined : getArrayF32FromWasm0(arg1, arg2), arg3 === 0 ? undefined : getArrayF32FromWasm0(arg3, arg4));
};

export const __wbg_new_59cb74e423758ede = function() {
    var ret = new Error();
    return addHeapObject(ret);
};

export const __wbg_stack_558ba5917b466edd = function(arg0, arg1) {
    var ret = getObject(arg1).stack;
    var ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export const __wbg_error_4bb6c2a97407129a = function(arg0, arg1) {
    try {
        console.error(getStringFromWasm0(arg0, arg1));
    } finally {
        wasm.__wbindgen_free(arg0, arg1);
    }
};

export const __wbindgen_is_undefined = function(arg0) {
    var ret = getObject(arg0) === undefined;
    return ret;
};

export const __wbg_instanceof_WebGl2RenderingContext_9818b789249374d3 = function(arg0) {
    var ret = getObject(arg0) instanceof WebGL2RenderingContext;
    return ret;
};

export const __wbg_bindVertexArray_569f8b5466293fb0 = function(arg0, arg1) {
    getObject(arg0).bindVertexArray(getObject(arg1));
};

export const __wbg_blitFramebuffer_4b472bb45062bd6e = function(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10) {
    getObject(arg0).blitFramebuffer(arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9 >>> 0, arg10 >>> 0);
};

export const __wbg_createVertexArray_1f35f6d163bbae13 = function(arg0) {
    var ret = getObject(arg0).createVertexArray();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_renderbufferStorageMultisample_0426ba65de4885db = function(arg0, arg1, arg2, arg3, arg4, arg5) {
    getObject(arg0).renderbufferStorageMultisample(arg1 >>> 0, arg2, arg3 >>> 0, arg4, arg5);
};

export const __wbg_texImage2D_79c0e000ef5e1b0a = handleError(function(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10) {
    getObject(arg0).texImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9 === 0 ? undefined : getArrayU8FromWasm0(arg9, arg10));
});

export const __wbg_bindFramebuffer_4a37c2a7678c0994 = function(arg0, arg1, arg2) {
    getObject(arg0).bindFramebuffer(arg1 >>> 0, getObject(arg2));
};

export const __wbg_bindRenderbuffer_203ad50a30ad3c0d = function(arg0, arg1, arg2) {
    getObject(arg0).bindRenderbuffer(arg1 >>> 0, getObject(arg2));
};

export const __wbg_bindTexture_f3ab6393f75a763f = function(arg0, arg1, arg2) {
    getObject(arg0).bindTexture(arg1 >>> 0, getObject(arg2));
};

export const __wbg_createFramebuffer_d01ac1b4f7c704e5 = function(arg0) {
    var ret = getObject(arg0).createFramebuffer();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_createRenderbuffer_c008c3bb50dfff9d = function(arg0) {
    var ret = getObject(arg0).createRenderbuffer();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_createTexture_151a385cd028c893 = function(arg0) {
    var ret = getObject(arg0).createTexture();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_deleteFramebuffer_acd92acda81356e9 = function(arg0, arg1) {
    getObject(arg0).deleteFramebuffer(getObject(arg1));
};

export const __wbg_deleteRenderbuffer_b67ff9026d2be0fd = function(arg0, arg1) {
    getObject(arg0).deleteRenderbuffer(getObject(arg1));
};

export const __wbg_deleteTexture_125ab82d8330e268 = function(arg0, arg1) {
    getObject(arg0).deleteTexture(getObject(arg1));
};

export const __wbg_framebufferRenderbuffer_5293612146bd869a = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).framebufferRenderbuffer(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, getObject(arg4));
};

export const __wbg_framebufferTexture2D_5b8575bda5aeceeb = function(arg0, arg1, arg2, arg3, arg4, arg5) {
    getObject(arg0).framebufferTexture2D(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0, getObject(arg4), arg5);
};

export const __wbg_getError_609a7fa815fd3d84 = function(arg0) {
    var ret = getObject(arg0).getError();
    return ret;
};

export const __wbg_getParameter_be1e4b3ba2c0c339 = handleError(function(arg0, arg1) {
    var ret = getObject(arg0).getParameter(arg1 >>> 0);
    return addHeapObject(ret);
});

export const __wbg_texParameteri_6e7ba8c54bb639f2 = function(arg0, arg1, arg2, arg3) {
    getObject(arg0).texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
};

export const __wbg_instanceof_Window_49f532f06a9786ee = function(arg0) {
    var ret = getObject(arg0) instanceof Window;
    return ret;
};

export const __wbg_document_c0366b39e4f4c89a = function(arg0) {
    var ret = getObject(arg0).document;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_location_c1e50a6e4c53d45c = function(arg0) {
    var ret = getObject(arg0).location;
    return addHeapObject(ret);
};

export const __wbg_navigator_95ba9cd684cf90aa = function(arg0) {
    var ret = getObject(arg0).navigator;
    return addHeapObject(ret);
};

export const __wbg_devicePixelRatio_268c49438a600d53 = function(arg0) {
    var ret = getObject(arg0).devicePixelRatio;
    return ret;
};

export const __wbg_performance_87e4f3b6f966469f = function(arg0) {
    var ret = getObject(arg0).performance;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_localStorage_a6fd83fc300473fc = handleError(function(arg0) {
    var ret = getObject(arg0).localStorage;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
});

export const __wbg_cancelAnimationFrame_60f9cf59ec1c0125 = handleError(function(arg0, arg1) {
    getObject(arg0).cancelAnimationFrame(arg1);
});

export const __wbg_focus_ab7792954990fdb9 = handleError(function(arg0) {
    getObject(arg0).focus();
});

export const __wbg_open_f355af0fd051a9d8 = handleError(function(arg0, arg1, arg2, arg3, arg4) {
    var ret = getObject(arg0).open(getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
});

export const __wbg_requestAnimationFrame_ef0e2294dc8b1088 = handleError(function(arg0, arg1) {
    var ret = getObject(arg0).requestAnimationFrame(getObject(arg1));
    return ret;
});

export const __wbg_fetch_f532e04b8fe49aa0 = function(arg0, arg1) {
    var ret = getObject(arg0).fetch(getObject(arg1));
    return addHeapObject(ret);
};

export const __wbg_destination_4b8bc48ce4ebcd6c = function(arg0) {
    var ret = getObject(arg0).destination;
    return addHeapObject(ret);
};

export const __wbg_sampleRate_0d2637cc084b2eaf = function(arg0) {
    var ret = getObject(arg0).sampleRate;
    return ret;
};

export const __wbg_currentTime_1bc83619afbf9e72 = function(arg0) {
    var ret = getObject(arg0).currentTime;
    return ret;
};

export const __wbg_new_2f70ca6da1976429 = handleError(function() {
    var ret = new lAudioContext();
    return addHeapObject(ret);
});

export const __wbg_suspend_ad6a97ee4bac3247 = handleError(function(arg0) {
    var ret = getObject(arg0).suspend();
    return addHeapObject(ret);
});

export const __wbg_createBuffer_48e051aa20c4ba3e = handleError(function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg0).createBuffer(arg1 >>> 0, arg2 >>> 0, arg3);
    return addHeapObject(ret);
});

export const __wbg_createBufferSource_0e160528349a924b = handleError(function(arg0) {
    var ret = getObject(arg0).createBufferSource();
    return addHeapObject(ret);
});

export const __wbg_createChannelMerger_8e8d29d53323df91 = handleError(function(arg0, arg1) {
    var ret = getObject(arg0).createChannelMerger(arg1 >>> 0);
    return addHeapObject(ret);
});

export const __wbg_createChannelSplitter_10fc5fe404818afc = handleError(function(arg0, arg1) {
    var ret = getObject(arg0).createChannelSplitter(arg1 >>> 0);
    return addHeapObject(ret);
});

export const __wbg_createGain_a6211e4c615a1141 = handleError(function(arg0) {
    var ret = getObject(arg0).createGain();
    return addHeapObject(ret);
});

export const __wbg_createScriptProcessor_e755612398f80dd4 = handleError(function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg0).createScriptProcessor(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0);
    return addHeapObject(ret);
});

export const __wbg_decodeAudioData_1a1e91ec5cd60d59 = handleError(function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg0).decodeAudioData(getObject(arg1), getObject(arg2), getObject(arg3));
    return addHeapObject(ret);
});

export const __wbg_resume_6dd89ae2f9b0bef3 = handleError(function(arg0) {
    var ret = getObject(arg0).resume();
    return addHeapObject(ret);
});

export const __wbg_linearRampToValueAtTime_02ce948b9b4323e0 = handleError(function(arg0, arg1, arg2) {
    var ret = getObject(arg0).linearRampToValueAtTime(arg1, arg2);
    return addHeapObject(ret);
});

export const __wbg_setValueAtTime_788b65cf85b7ba6a = handleError(function(arg0, arg1, arg2) {
    var ret = getObject(arg0).setValueAtTime(arg1, arg2);
    return addHeapObject(ret);
});

export const __wbg_userAgent_b9c7a338061adca8 = handleError(function(arg0, arg1) {
    var ret = getObject(arg1).userAgent;
    var ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
});

export const __wbg_baseURI_1fe61dc0a39a61ed = handleError(function(arg0, arg1) {
    var ret = getObject(arg1).baseURI;
    var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
});

export const __wbg_appendChild_7c45aeccd496f2a5 = handleError(function(arg0, arg1) {
    var ret = getObject(arg0).appendChild(getObject(arg1));
    return addHeapObject(ret);
});

export const __wbg_gain_688543a4eee1eece = function(arg0) {
    var ret = getObject(arg0).gain;
    return addHeapObject(ret);
};

export const __wbg_assign_8948549eb7c584b7 = handleError(function(arg0, arg1, arg2) {
    getObject(arg0).assign(getStringFromWasm0(arg1, arg2));
});

export const __wbg_new_0af3e1641443f13d = handleError(function() {
    var ret = new Path2D();
    return addHeapObject(ret);
});

export const __wbg_addPath_0b9c6299797f0772 = function(arg0, arg1, arg2) {
    getObject(arg0).addPath(getObject(arg1), getObject(arg2));
};

export const __wbg_closePath_6e9529e4d6da5e83 = function(arg0) {
    getObject(arg0).closePath();
};

export const __wbg_lineTo_2157bf4d1b0ce0d3 = function(arg0, arg1, arg2) {
    getObject(arg0).lineTo(arg1, arg2);
};

export const __wbg_moveTo_fb2215902520c649 = function(arg0, arg1, arg2) {
    getObject(arg0).moveTo(arg1, arg2);
};

export const __wbg_quadraticCurveTo_9c392c57a794a2d2 = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).quadraticCurveTo(arg1, arg2, arg3, arg4);
};

export const __wbg_get_29a9ce40981b8710 = handleError(function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg1)[getStringFromWasm0(arg2, arg3)];
    var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
});

export const __wbg_set_13ecdf3c7967382d = handleError(function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0)[getStringFromWasm0(arg1, arg2)] = getStringFromWasm0(arg3, arg4);
});

export const __wbg_delete_2c0adda5d0edea03 = handleError(function(arg0, arg1, arg2) {
    delete getObject(arg0)[getStringFromWasm0(arg1, arg2)];
});

export const __wbg_length_da24898717a463e0 = function(arg0) {
    var ret = getObject(arg0).length;
    return ret;
};

export const __wbg_setProperty_46b9bd1b0fad730b = handleError(function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).setProperty(getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
});

export const __wbg_offsetX_4bd8c9fcb457cf0b = function(arg0) {
    var ret = getObject(arg0).offsetX;
    return ret;
};

export const __wbg_offsetY_0dde12490e8ebfba = function(arg0) {
    var ret = getObject(arg0).offsetY;
    return ret;
};

export const __wbg_button_9e74bd912190b055 = function(arg0) {
    var ret = getObject(arg0).button;
    return ret;
};

export const __wbg_instanceof_SvgsvgElement_aa3f79a2638c5f44 = function(arg0) {
    var ret = getObject(arg0) instanceof SVGSVGElement;
    return ret;
};

export const __wbg_createSVGMatrix_9088b605b3cbb935 = function(arg0) {
    var ret = getObject(arg0).createSVGMatrix();
    return addHeapObject(ret);
};

export const __wbg_connect_607a0c51b546a3db = handleError(function(arg0, arg1) {
    var ret = getObject(arg0).connect(getObject(arg1));
    return addHeapObject(ret);
});

export const __wbg_connect_a4236fe1b645b8fb = handleError(function(arg0, arg1, arg2) {
    var ret = getObject(arg0).connect(getObject(arg1), arg2 >>> 0);
    return addHeapObject(ret);
});

export const __wbg_connect_db97a7bc9cd258c6 = handleError(function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg0).connect(getObject(arg1), arg2 >>> 0, arg3 >>> 0);
    return addHeapObject(ret);
});

export const __wbg_disconnect_1f5d0443b0bca9c8 = handleError(function(arg0) {
    getObject(arg0).disconnect();
});

export const __wbg_outputBuffer_1fc843f5ddfd743b = handleError(function(arg0) {
    var ret = getObject(arg0).outputBuffer;
    return addHeapObject(ret);
});

export const __wbg_setTransform_041d67e3d6972fd7 = function(arg0, arg1) {
    getObject(arg0).setTransform(getObject(arg1));
};

export const __wbg_bindVertexArrayOES_17e98f43d896f40b = function(arg0, arg1) {
    getObject(arg0).bindVertexArrayOES(getObject(arg1));
};

export const __wbg_createVertexArrayOES_393e00fa6f931f59 = function(arg0) {
    var ret = getObject(arg0).createVertexArrayOES();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_seta_6c309b33d9a4fb29 = function(arg0, arg1) {
    getObject(arg0).a = arg1;
};

export const __wbg_setb_378147d7ba767ab1 = function(arg0, arg1) {
    getObject(arg0).b = arg1;
};

export const __wbg_setc_cc36b264b7a1ae60 = function(arg0, arg1) {
    getObject(arg0).c = arg1;
};

export const __wbg_setd_998c5989f5780653 = function(arg0, arg1) {
    getObject(arg0).d = arg1;
};

export const __wbg_sete_42bf2f390f25e536 = function(arg0, arg1) {
    getObject(arg0).e = arg1;
};

export const __wbg_setf_df5aef1469b0c50b = function(arg0, arg1) {
    getObject(arg0).f = arg1;
};

export const __wbg_deltaY_2722120e563d3160 = function(arg0) {
    var ret = getObject(arg0).deltaY;
    return ret;
};

export const __wbg_deltaMode_3db3c9c4bedf191d = function(arg0) {
    var ret = getObject(arg0).deltaMode;
    return ret;
};

export const __wbg_setbuffer_8c396e74724eda12 = function(arg0, arg1) {
    getObject(arg0).buffer = getObject(arg1);
};

export const __wbg_setloop_1e141edefc26db22 = function(arg0, arg1) {
    getObject(arg0).loop = arg1 !== 0;
};

export const __wbg_setloopStart_4162b0a533fbc0d5 = function(arg0, arg1) {
    getObject(arg0).loopStart = arg1;
};

export const __wbg_setloopEnd_e81eb72ea2277d88 = function(arg0, arg1) {
    getObject(arg0).loopEnd = arg1;
};

export const __wbg_setonended_f6f3e04e13c07db4 = function(arg0, arg1) {
    getObject(arg0).onended = getObject(arg1);
};

export const __wbg_start_f5e66921db9afaee = handleError(function(arg0) {
    getObject(arg0).start();
});

export const __wbg_start_0237410bb20c6213 = handleError(function(arg0, arg1, arg2) {
    getObject(arg0).start(arg1, arg2);
});

export const __wbg_stop_5765c7cac3ec1564 = handleError(function(arg0, arg1) {
    getObject(arg0).stop(arg1);
});

export const __wbg_instanceof_HtmlFormElement_26283084b0956b17 = function(arg0) {
    var ret = getObject(arg0) instanceof HTMLFormElement;
    return ret;
};

export const __wbg_submit_121b8e3bd960f229 = handleError(function(arg0) {
    getObject(arg0).submit();
});

export const __wbg_pointerId_602db5c989b38cc0 = function(arg0) {
    var ret = getObject(arg0).pointerId;
    return ret;
};

export const __wbg_instanceof_Response_f52c65c389890639 = function(arg0) {
    var ret = getObject(arg0) instanceof Response;
    return ret;
};

export const __wbg_arrayBuffer_0ba17dfaad804b6f = handleError(function(arg0) {
    var ret = getObject(arg0).arrayBuffer();
    return addHeapObject(ret);
});

export const __wbg_key_d9b602f48baca7bc = function(arg0, arg1) {
    var ret = getObject(arg1).key;
    var ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export const __wbg_code_cbf76ad384ae1179 = function(arg0, arg1) {
    var ret = getObject(arg1).code;
    var ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export const __wbg_now_7628760b7b640632 = function(arg0) {
    var ret = getObject(arg0).now();
    return ret;
};

export const __wbg_newwithstrandinit_11debb554792e043 = handleError(function(arg0, arg1, arg2) {
    var ret = new Request(getStringFromWasm0(arg0, arg1), getObject(arg2));
    return addHeapObject(ret);
});

export const __wbg_setonaudioprocess_66d0b1fa0dd39606 = function(arg0, arg1) {
    getObject(arg0).onaudioprocess = getObject(arg1);
};

export const __wbg_body_c8cb19d760637268 = function(arg0) {
    var ret = getObject(arg0).body;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_createElement_99351c8bf0efac6e = handleError(function(arg0, arg1, arg2) {
    var ret = getObject(arg0).createElement(getStringFromWasm0(arg1, arg2));
    return addHeapObject(ret);
});

export const __wbg_createElementNS_a7ef126eff5022c2 = handleError(function(arg0, arg1, arg2, arg3, arg4) {
    var ret = getObject(arg0).createElementNS(arg1 === 0 ? undefined : getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
    return addHeapObject(ret);
});

export const __wbg_setid_f33ce4e43b43f57a = function(arg0, arg1, arg2) {
    getObject(arg0).id = getStringFromWasm0(arg1, arg2);
};

export const __wbg_clientWidth_2c84a7ebc75dd4f0 = function(arg0) {
    var ret = getObject(arg0).clientWidth;
    return ret;
};

export const __wbg_clientHeight_f56dfe03d1c42f3e = function(arg0) {
    var ret = getObject(arg0).clientHeight;
    return ret;
};

export const __wbg_querySelector_155b00165b78a12f = handleError(function(arg0, arg1, arg2) {
    var ret = getObject(arg0).querySelector(getStringFromWasm0(arg1, arg2));
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
});

export const __wbg_releasePointerCapture_4b78cb9c51d4a539 = handleError(function(arg0, arg1) {
    getObject(arg0).releasePointerCapture(arg1);
});

export const __wbg_setAttribute_e71b9086539f06a1 = handleError(function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).setAttribute(getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4));
});

export const __wbg_setAttributeNS_a66240d5c750d0fa = handleError(function(arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    getObject(arg0).setAttributeNS(arg1 === 0 ? undefined : getStringFromWasm0(arg1, arg2), getStringFromWasm0(arg3, arg4), getStringFromWasm0(arg5, arg6));
});

export const __wbg_setPointerCapture_54ee987062d42d03 = handleError(function(arg0, arg1) {
    getObject(arg0).setPointerCapture(arg1);
});

export const __wbg_remove_0639495587b1aaeb = function(arg0) {
    getObject(arg0).remove();
};

export const __wbg_instanceof_WebGlRenderingContext_ef4e51c6e4133d85 = function(arg0) {
    var ret = getObject(arg0) instanceof WebGLRenderingContext;
    return ret;
};

export const __wbg_drawingBufferWidth_fab3e20dd1ba5d4e = function(arg0) {
    var ret = getObject(arg0).drawingBufferWidth;
    return ret;
};

export const __wbg_drawingBufferHeight_31bc93d4dc3a0dcc = function(arg0) {
    var ret = getObject(arg0).drawingBufferHeight;
    return ret;
};

export const __wbg_bufferData_d47c8467e443121d = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).bufferData(arg1 >>> 0, getArrayU8FromWasm0(arg2, arg3), arg4 >>> 0);
};

export const __wbg_texImage2D_a4011abffe0229fb = handleError(function(arg0, arg1, arg2, arg3, arg4, arg5, arg6, arg7, arg8, arg9, arg10) {
    getObject(arg0).texImage2D(arg1 >>> 0, arg2, arg3, arg4, arg5, arg6, arg7 >>> 0, arg8 >>> 0, arg9 === 0 ? undefined : getArrayU8FromWasm0(arg9, arg10));
});

export const __wbg_uniform1fv_cd9d2dea0641919d = function(arg0, arg1, arg2, arg3) {
    getObject(arg0).uniform1fv(getObject(arg1), getArrayF32FromWasm0(arg2, arg3));
};

export const __wbg_uniform4fv_24ef46675c137552 = function(arg0, arg1, arg2, arg3) {
    getObject(arg0).uniform4fv(getObject(arg1), getArrayF32FromWasm0(arg2, arg3));
};

export const __wbg_uniformMatrix3fv_a0a261980af4587d = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).uniformMatrix3fv(getObject(arg1), arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export const __wbg_uniformMatrix4fv_088c96db8ee28c1d = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).uniformMatrix4fv(getObject(arg1), arg2 !== 0, getArrayF32FromWasm0(arg3, arg4));
};

export const __wbg_activeTexture_a51ec6273de88bc6 = function(arg0, arg1) {
    getObject(arg0).activeTexture(arg1 >>> 0);
};

export const __wbg_attachShader_0dd248f6ab98fcf2 = function(arg0, arg1, arg2) {
    getObject(arg0).attachShader(getObject(arg1), getObject(arg2));
};

export const __wbg_bindBuffer_1ceb83e9674e812a = function(arg0, arg1, arg2) {
    getObject(arg0).bindBuffer(arg1 >>> 0, getObject(arg2));
};

export const __wbg_bindFramebuffer_d8f98d1a7f6d67d1 = function(arg0, arg1, arg2) {
    getObject(arg0).bindFramebuffer(arg1 >>> 0, getObject(arg2));
};

export const __wbg_bindRenderbuffer_1ba9206d5f319ae1 = function(arg0, arg1, arg2) {
    getObject(arg0).bindRenderbuffer(arg1 >>> 0, getObject(arg2));
};

export const __wbg_bindTexture_6121e6db3f879582 = function(arg0, arg1, arg2) {
    getObject(arg0).bindTexture(arg1 >>> 0, getObject(arg2));
};

export const __wbg_blendFunc_34a6bb31770822c5 = function(arg0, arg1, arg2) {
    getObject(arg0).blendFunc(arg1 >>> 0, arg2 >>> 0);
};

export const __wbg_clear_f6b2dd48aeed2752 = function(arg0, arg1) {
    getObject(arg0).clear(arg1 >>> 0);
};

export const __wbg_clearColor_89f7819aa9f80129 = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).clearColor(arg1, arg2, arg3, arg4);
};

export const __wbg_colorMask_88ebee15531b14ef = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).colorMask(arg1 !== 0, arg2 !== 0, arg3 !== 0, arg4 !== 0);
};

export const __wbg_compileShader_28bdbafe4445d24b = function(arg0, arg1) {
    getObject(arg0).compileShader(getObject(arg1));
};

export const __wbg_createBuffer_acedc3831832a280 = function(arg0) {
    var ret = getObject(arg0).createBuffer();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_createProgram_7e2f44b7b74694d4 = function(arg0) {
    var ret = getObject(arg0).createProgram();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_createShader_64c474f1d1d0c1f8 = function(arg0, arg1) {
    var ret = getObject(arg0).createShader(arg1 >>> 0);
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_createTexture_0a156dab1efc3499 = function(arg0) {
    var ret = getObject(arg0).createTexture();
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_disable_5b9c6f74d5efd3a5 = function(arg0, arg1) {
    getObject(arg0).disable(arg1 >>> 0);
};

export const __wbg_disableVertexAttribArray_ba8f39ecd28c601b = function(arg0, arg1) {
    getObject(arg0).disableVertexAttribArray(arg1 >>> 0);
};

export const __wbg_drawElements_3eb5ba8a511ce0f0 = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).drawElements(arg1 >>> 0, arg2, arg3 >>> 0, arg4);
};

export const __wbg_enable_87f39f6396535e1f = function(arg0, arg1) {
    getObject(arg0).enable(arg1 >>> 0);
};

export const __wbg_enableVertexAttribArray_f29c8dde9c8c5cf5 = function(arg0, arg1) {
    getObject(arg0).enableVertexAttribArray(arg1 >>> 0);
};

export const __wbg_getAttribLocation_ba61f837da80e249 = function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg0).getAttribLocation(getObject(arg1), getStringFromWasm0(arg2, arg3));
    return ret;
};

export const __wbg_getExtension_c6863c255090d82f = handleError(function(arg0, arg1, arg2) {
    var ret = getObject(arg0).getExtension(getStringFromWasm0(arg1, arg2));
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
});

export const __wbg_getParameter_ff687a6ea303e551 = handleError(function(arg0, arg1) {
    var ret = getObject(arg0).getParameter(arg1 >>> 0);
    return addHeapObject(ret);
});

export const __wbg_getProgramInfoLog_aacf06c959070653 = function(arg0, arg1, arg2) {
    var ret = getObject(arg1).getProgramInfoLog(getObject(arg2));
    var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export const __wbg_getProgramParameter_a89bf14502c109f7 = function(arg0, arg1, arg2) {
    var ret = getObject(arg0).getProgramParameter(getObject(arg1), arg2 >>> 0);
    return addHeapObject(ret);
};

export const __wbg_getShaderInfoLog_1eb885f2468e2429 = function(arg0, arg1, arg2) {
    var ret = getObject(arg1).getShaderInfoLog(getObject(arg2));
    var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export const __wbg_getUniformLocation_ca853de4f2f9270d = function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg0).getUniformLocation(getObject(arg1), getStringFromWasm0(arg2, arg3));
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_linkProgram_46a36cb158f10676 = function(arg0, arg1) {
    getObject(arg0).linkProgram(getObject(arg1));
};

export const __wbg_pixelStorei_31f6dc244bf4cd5b = function(arg0, arg1, arg2) {
    getObject(arg0).pixelStorei(arg1 >>> 0, arg2);
};

export const __wbg_scissor_59172d697cc43dc8 = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).scissor(arg1, arg2, arg3, arg4);
};

export const __wbg_shaderSource_700ae72fca39850d = function(arg0, arg1, arg2, arg3) {
    getObject(arg0).shaderSource(getObject(arg1), getStringFromWasm0(arg2, arg3));
};

export const __wbg_stencilFunc_cc85d77d2098446b = function(arg0, arg1, arg2, arg3) {
    getObject(arg0).stencilFunc(arg1 >>> 0, arg2, arg3 >>> 0);
};

export const __wbg_stencilMask_ed2cded2c2b0b72c = function(arg0, arg1) {
    getObject(arg0).stencilMask(arg1 >>> 0);
};

export const __wbg_stencilOp_1b3529546c1fdcc2 = function(arg0, arg1, arg2, arg3) {
    getObject(arg0).stencilOp(arg1 >>> 0, arg2 >>> 0, arg3 >>> 0);
};

export const __wbg_texParameteri_e45f3977eb998137 = function(arg0, arg1, arg2, arg3) {
    getObject(arg0).texParameteri(arg1 >>> 0, arg2 >>> 0, arg3);
};

export const __wbg_uniform1f_3eb09312a513b94a = function(arg0, arg1, arg2) {
    getObject(arg0).uniform1f(getObject(arg1), arg2);
};

export const __wbg_uniform1i_e76b668973ae0655 = function(arg0, arg1, arg2) {
    getObject(arg0).uniform1i(getObject(arg1), arg2);
};

export const __wbg_useProgram_d63a57db0571e803 = function(arg0, arg1) {
    getObject(arg0).useProgram(getObject(arg1));
};

export const __wbg_vertexAttribPointer_b4b829a4f5a3778e = function(arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    getObject(arg0).vertexAttribPointer(arg1 >>> 0, arg2, arg3 >>> 0, arg4 !== 0, arg5, arg6);
};

export const __wbg_viewport_54305c74f5668b33 = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).viewport(arg1, arg2, arg3, arg4);
};

export const __wbg_debug_146b863607d79e9d = function(arg0) {
    console.debug(getObject(arg0));
};

export const __wbg_error_e325755affc8634b = function(arg0) {
    console.error(getObject(arg0));
};

export const __wbg_info_d60054f760c729cc = function(arg0) {
    console.info(getObject(arg0));
};

export const __wbg_log_f2e13ca55da8bad3 = function(arg0) {
    console.log(getObject(arg0));
};

export const __wbg_warn_9e92ccdc67085e1b = function(arg0) {
    console.warn(getObject(arg0));
};

export const __wbg_style_9b773f0fc441eddc = function(arg0) {
    var ret = getObject(arg0).style;
    return addHeapObject(ret);
};

export const __wbg_newwithbuffersourcesequenceandoptions_03294a518a0bd99a = handleError(function(arg0, arg1) {
    var ret = new Blob(getObject(arg0), getObject(arg1));
    return addHeapObject(ret);
});

export const __wbg_currentTarget_b545d0d803b3a62e = function(arg0) {
    var ret = getObject(arg0).currentTarget;
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
};

export const __wbg_preventDefault_9aab6c264e5df3ee = function(arg0) {
    getObject(arg0).preventDefault();
};

export const __wbg_addEventListener_6a37bc32387cb66d = handleError(function(arg0, arg1, arg2, arg3) {
    getObject(arg0).addEventListener(getStringFromWasm0(arg1, arg2), getObject(arg3));
});

export const __wbg_addEventListener_a422088e686210b5 = handleError(function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).addEventListener(getStringFromWasm0(arg1, arg2), getObject(arg3), getObject(arg4));
});

export const __wbg_addEventListener_50b4229556ab0b83 = handleError(function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).addEventListener(getStringFromWasm0(arg1, arg2), getObject(arg3), arg4 !== 0);
});

export const __wbg_instanceof_HtmlCanvasElement_7bd3ee7838f11fc3 = function(arg0) {
    var ret = getObject(arg0) instanceof HTMLCanvasElement;
    return ret;
};

export const __wbg_width_0efa4604d41c58c5 = function(arg0) {
    var ret = getObject(arg0).width;
    return ret;
};

export const __wbg_setwidth_1d0e975feecff3ef = function(arg0, arg1) {
    getObject(arg0).width = arg1 >>> 0;
};

export const __wbg_height_aa24e3fef658c4a8 = function(arg0) {
    var ret = getObject(arg0).height;
    return ret;
};

export const __wbg_setheight_7758ee3ff5c65474 = function(arg0, arg1) {
    getObject(arg0).height = arg1 >>> 0;
};

export const __wbg_getContext_3db9399e6dc524ff = handleError(function(arg0, arg1, arg2) {
    var ret = getObject(arg0).getContext(getStringFromWasm0(arg1, arg2));
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
});

export const __wbg_getContext_93be69215ea9dbbf = handleError(function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg0).getContext(getStringFromWasm0(arg1, arg2), getObject(arg3));
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
});

export const __wbg_setsrc_ca894d724570195d = function(arg0, arg1, arg2) {
    getObject(arg0).src = getStringFromWasm0(arg1, arg2);
};

export const __wbg_new_1f2ded01c889cd38 = handleError(function() {
    var ret = new Image();
    return addHeapObject(ret);
});

export const __wbg_newwithwidthandheight_0f3a13502ca94c49 = handleError(function(arg0, arg1) {
    var ret = new Image(arg0 >>> 0, arg1 >>> 0);
    return addHeapObject(ret);
});

export const __wbg_data_55e064fddf34cf3b = function(arg0, arg1) {
    var ret = getObject(arg1).data;
    var ptr0 = passArray8ToWasm0(ret, wasm.__wbindgen_malloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export const __wbg_instanceof_CanvasRenderingContext2d_1d38418d1d6c8b34 = function(arg0) {
    var ret = getObject(arg0) instanceof CanvasRenderingContext2D;
    return ret;
};

export const __wbg_setglobalAlpha_b4134dbd884b4d37 = function(arg0, arg1) {
    getObject(arg0).globalAlpha = arg1;
};

export const __wbg_setglobalCompositeOperation_557a59a0ccf673d6 = handleError(function(arg0, arg1, arg2) {
    getObject(arg0).globalCompositeOperation = getStringFromWasm0(arg1, arg2);
});

export const __wbg_setstrokeStyle_7f507e0d838e3dcf = function(arg0, arg1) {
    getObject(arg0).strokeStyle = getObject(arg1);
};

export const __wbg_setfillStyle_bbe97cb93eb1b55c = function(arg0, arg1) {
    getObject(arg0).fillStyle = getObject(arg1);
};

export const __wbg_setfilter_df75805bea0a8415 = function(arg0, arg1, arg2) {
    getObject(arg0).filter = getStringFromWasm0(arg1, arg2);
};

export const __wbg_setlineWidth_61de9d1eb4e9f8a6 = function(arg0, arg1) {
    getObject(arg0).lineWidth = arg1;
};

export const __wbg_setlineCap_bff1a8858c9f067e = function(arg0, arg1, arg2) {
    getObject(arg0).lineCap = getStringFromWasm0(arg1, arg2);
};

export const __wbg_setlineJoin_de47815c918d7874 = function(arg0, arg1, arg2) {
    getObject(arg0).lineJoin = getStringFromWasm0(arg1, arg2);
};

export const __wbg_setmiterLimit_84b1a1dbddcd456a = function(arg0, arg1) {
    getObject(arg0).miterLimit = arg1;
};

export const __wbg_drawImage_b8dec9034bf1d116 = handleError(function(arg0, arg1, arg2, arg3) {
    getObject(arg0).drawImage(getObject(arg1), arg2, arg3);
});

export const __wbg_drawImage_801ad094f4734689 = handleError(function(arg0, arg1, arg2, arg3) {
    getObject(arg0).drawImage(getObject(arg1), arg2, arg3);
});

export const __wbg_fill_6c721ab8eec90f70 = function(arg0, arg1) {
    getObject(arg0).fill(getObject(arg1));
};

export const __wbg_stroke_e25cf47e0d9a36c3 = function(arg0, arg1) {
    getObject(arg0).stroke(getObject(arg1));
};

export const __wbg_createPattern_006b6402db95df36 = handleError(function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg0).createPattern(getObject(arg1), getStringFromWasm0(arg2, arg3));
    return isLikeNone(ret) ? 0 : addHeapObject(ret);
});

export const __wbg_getImageData_dd3caac873bf461f = handleError(function(arg0, arg1, arg2, arg3, arg4) {
    var ret = getObject(arg0).getImageData(arg1, arg2, arg3, arg4);
    return addHeapObject(ret);
});

export const __wbg_clearRect_6af85454d06df5fa = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).clearRect(arg1, arg2, arg3, arg4);
};

export const __wbg_fillRect_c79d1b386c04efed = function(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).fillRect(arg1, arg2, arg3, arg4);
};

export const __wbg_resetTransform_f78edb82fd1f7239 = handleError(function(arg0) {
    getObject(arg0).resetTransform();
});

export const __wbg_setTransform_5f5f19301b76a8d6 = handleError(function(arg0, arg1, arg2, arg3, arg4, arg5, arg6) {
    getObject(arg0).setTransform(arg1, arg2, arg3, arg4, arg5, arg6);
});

export const __wbg_get_5fa3f454aa041e6e = function(arg0, arg1) {
    var ret = getObject(arg0)[arg1 >>> 0];
    return addHeapObject(ret);
};

export const __wbg_next_373211328013f949 = handleError(function(arg0) {
    var ret = getObject(arg0).next();
    return addHeapObject(ret);
});

export const __wbg_done_49c598117f977077 = function(arg0) {
    var ret = getObject(arg0).done;
    return ret;
};

export const __wbg_value_c9ae6368b110a068 = function(arg0) {
    var ret = getObject(arg0).value;
    return addHeapObject(ret);
};

export const __wbg_get_85e0a3b459845fe2 = handleError(function(arg0, arg1) {
    var ret = Reflect.get(getObject(arg0), getObject(arg1));
    return addHeapObject(ret);
});

export const __wbg_call_951bd0c6d815d6f1 = handleError(function(arg0, arg1) {
    var ret = getObject(arg0).call(getObject(arg1));
    return addHeapObject(ret);
});

export const __wbg_new_9dff83a08f5994f3 = function() {
    var ret = new Array();
    return addHeapObject(ret);
};

export const __wbg_isArray_cf56c8363b1b35d9 = function(arg0) {
    var ret = Array.isArray(getObject(arg0));
    return ret;
};

export const __wbg_of_3a4bb382824dd437 = function(arg0, arg1) {
    var ret = Array.of(getObject(arg0), getObject(arg1));
    return addHeapObject(ret);
};

export const __wbg_push_3ddd8187ff2ff82d = function(arg0, arg1) {
    var ret = getObject(arg0).push(getObject(arg1));
    return ret;
};

export const __wbg_instanceof_ArrayBuffer_3a0fa134e6809d57 = function(arg0) {
    var ret = getObject(arg0) instanceof ArrayBuffer;
    return ret;
};

export const __wbg_new_24c0ca5f23ee2dd9 = function(arg0) {
    var ret = new ArrayBuffer(arg0 >>> 0);
    return addHeapObject(ret);
};

export const __wbg_slice_a498e5bb17f253f3 = function(arg0, arg1, arg2) {
    var ret = getObject(arg0).slice(arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
};

export const __wbg_values_f28e313e2260a03a = function(arg0) {
    var ret = getObject(arg0).values();
    return addHeapObject(ret);
};

export const __wbg_newnoargs_7c6bd521992b4022 = function(arg0, arg1) {
    var ret = new Function(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
};

export const __wbg_apply_4e68243803368d89 = handleError(function(arg0, arg1, arg2) {
    var ret = getObject(arg0).apply(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
});

export const __wbg_call_bf745b1758bb6693 = handleError(function(arg0, arg1, arg2) {
    var ret = getObject(arg0).call(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
});

export const __wbg_getTime_cf686ab22ab03a3e = function(arg0) {
    var ret = getObject(arg0).getTime();
    return ret;
};

export const __wbg_getTimezoneOffset_b9f3c4664b1a35ae = function(arg0) {
    var ret = getObject(arg0).getTimezoneOffset();
    return ret;
};

export const __wbg_new0_abd359df4aeb5b55 = function() {
    var ret = new Date();
    return addHeapObject(ret);
};

export const __wbg_instanceof_Object_cdaa71ad2ca2f4c5 = function(arg0) {
    var ret = getObject(arg0) instanceof Object;
    return ret;
};

export const __wbg_entries_7144a7309b22df64 = function(arg0) {
    var ret = Object.entries(getObject(arg0));
    return addHeapObject(ret);
};

export const __wbg_fromEntries_cc1186a09f1ec7f2 = handleError(function(arg0) {
    var ret = Object.fromEntries(getObject(arg0));
    return addHeapObject(ret);
});

export const __wbg_is_049b1aece40b5301 = function(arg0, arg1) {
    var ret = Object.is(getObject(arg0), getObject(arg1));
    return ret;
};

export const __wbg_new_ba07d0daa0e4677e = function() {
    var ret = new Object();
    return addHeapObject(ret);
};

export const __wbg_resolve_6e61e640925a0db9 = function(arg0) {
    var ret = Promise.resolve(getObject(arg0));
    return addHeapObject(ret);
};

export const __wbg_then_dd3785597974798a = function(arg0, arg1) {
    var ret = getObject(arg0).then(getObject(arg1));
    return addHeapObject(ret);
};

export const __wbg_then_0f957e0f4c3e537a = function(arg0, arg1, arg2) {
    var ret = getObject(arg0).then(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
};

export const __wbg_self_6baf3a3aa7b63415 = handleError(function() {
    var ret = self.self;
    return addHeapObject(ret);
});

export const __wbg_window_63fc4027b66c265b = handleError(function() {
    var ret = window.window;
    return addHeapObject(ret);
});

export const __wbg_globalThis_513fb247e8e4e6d2 = handleError(function() {
    var ret = globalThis.globalThis;
    return addHeapObject(ret);
});

export const __wbg_global_b87245cd886d7113 = handleError(function() {
    var ret = global.global;
    return addHeapObject(ret);
});

export const __wbg_buffer_3f12a1c608c6d04e = function(arg0) {
    var ret = getObject(arg0).buffer;
    return addHeapObject(ret);
};

export const __wbg_newwithbyteoffsetandlength_4c51342f87299c5a = function(arg0, arg1, arg2) {
    var ret = new Uint8Array(getObject(arg0), arg1 >>> 0, arg2 >>> 0);
    return addHeapObject(ret);
};

export const __wbg_length_c645e7c02233b440 = function(arg0) {
    var ret = getObject(arg0).length;
    return ret;
};

export const __wbg_new_c6c0228e6d22a2f9 = function(arg0) {
    var ret = new Uint8Array(getObject(arg0));
    return addHeapObject(ret);
};

export const __wbg_set_b91afac9fd216d99 = function(arg0, arg1, arg2) {
    getObject(arg0).set(getObject(arg1), arg2 >>> 0);
};

export const __wbg_fill_f49407f577541856 = function(arg0, arg1, arg2, arg3) {
    var ret = getObject(arg0).fill(arg1, arg2 >>> 0, arg3 >>> 0);
    return addHeapObject(ret);
};

export const __wbg_buffer_c385539cb4060297 = function(arg0) {
    var ret = getObject(arg0).buffer;
    return addHeapObject(ret);
};

export const __wbg_byteLength_11e6bdc2fac53a3c = function(arg0) {
    var ret = getObject(arg0).byteLength;
    return ret;
};

export const __wbg_byteOffset_5db4e07752c85395 = function(arg0) {
    var ret = getObject(arg0).byteOffset;
    return ret;
};

export const __wbg_ownKeys_d44c25f33e28bfab = handleError(function(arg0) {
    var ret = Reflect.ownKeys(getObject(arg0));
    return addHeapObject(ret);
});

export const __wbg_set_9bdd413385146137 = handleError(function(arg0, arg1, arg2) {
    var ret = Reflect.set(getObject(arg0), getObject(arg1), getObject(arg2));
    return ret;
});

export const __wbindgen_number_get = function(arg0, arg1) {
    const obj = getObject(arg1);
    var ret = typeof(obj) === 'number' ? obj : undefined;
    getFloat64Memory0()[arg0 / 8 + 1] = isLikeNone(ret) ? 0 : ret;
    getInt32Memory0()[arg0 / 4 + 0] = !isLikeNone(ret);
};

export const __wbindgen_string_get = function(arg0, arg1) {
    const obj = getObject(arg1);
    var ret = typeof(obj) === 'string' ? obj : undefined;
    var ptr0 = isLikeNone(ret) ? 0 : passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export const __wbindgen_boolean_get = function(arg0) {
    const v = getObject(arg0);
    var ret = typeof(v) === 'boolean' ? (v ? 1 : 0) : 2;
    return ret;
};

export const __wbindgen_debug_string = function(arg0, arg1) {
    var ret = debugString(getObject(arg1));
    var ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    var len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export const __wbindgen_throw = function(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

export const __wbindgen_rethrow = function(arg0) {
    throw takeObject(arg0);
};

export const __wbindgen_memory = function() {
    var ret = wasm.memory;
    return addHeapObject(ret);
};

export const __wbindgen_closure_wrapper565 = function(arg0, arg1, arg2) {
    var ret = makeMutClosure(arg0, arg1, 191, __wbg_adapter_30);
    return addHeapObject(ret);
};

export const __wbindgen_closure_wrapper567 = function(arg0, arg1, arg2) {
    var ret = makeMutClosure(arg0, arg1, 191, __wbg_adapter_33);
    return addHeapObject(ret);
};

export const __wbindgen_closure_wrapper569 = function(arg0, arg1, arg2) {
    var ret = makeMutClosure(arg0, arg1, 191, __wbg_adapter_36);
    return addHeapObject(ret);
};

export const __wbindgen_closure_wrapper571 = function(arg0, arg1, arg2) {
    var ret = makeMutClosure(arg0, arg1, 191, __wbg_adapter_39);
    return addHeapObject(ret);
};

export const __wbindgen_closure_wrapper573 = function(arg0, arg1, arg2) {
    var ret = makeMutClosure(arg0, arg1, 191, __wbg_adapter_42);
    return addHeapObject(ret);
};

export const __wbindgen_closure_wrapper575 = function(arg0, arg1, arg2) {
    var ret = makeMutClosure(arg0, arg1, 191, __wbg_adapter_45);
    return addHeapObject(ret);
};

export const __wbindgen_closure_wrapper577 = function(arg0, arg1, arg2) {
    var ret = makeMutClosure(arg0, arg1, 191, __wbg_adapter_48);
    return addHeapObject(ret);
};

export const __wbindgen_closure_wrapper579 = function(arg0, arg1, arg2) {
    var ret = makeMutClosure(arg0, arg1, 191, __wbg_adapter_51);
    return addHeapObject(ret);
};

export const __wbindgen_closure_wrapper1256 = function(arg0, arg1, arg2) {
    var ret = makeMutClosure(arg0, arg1, 515, __wbg_adapter_54);
    return addHeapObject(ret);
};

